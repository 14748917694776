import React, { ReactNode, RefObject, useEffect, useState } from "react";

import { StyledTopButton } from "./styles";

export type TopButtonProps = {
  target: RefObject<HTMLElement>;
  children?: ReactNode;
};

const TopButton: React.FC<TopButtonProps> = (props) => {
  const { target, children } = props;
  const [visible, setVisible] = useState(false);
  const handelOnScroll = () => {
    const DISPLAY_OFFSET = 700;
    const top = target.current?.getBoundingClientRect().top || 0;
    setVisible(window.scrollY - top > DISPLAY_OFFSET);
  };
  const handleOnClick = () => {
    window.scrollTo({
      top: target.current?.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handelOnScroll);
    return () => {
      window.removeEventListener("scroll", handelOnScroll);
    };
  }, []);

  return (
    <StyledTopButton
      invisible={!visible}
      data-qa="ToTheTopButton"
      onClick={handleOnClick}
      aria-label="Nach oben scrollen"
    >
      {children}
    </StyledTopButton>
  );
};

export default TopButton;
