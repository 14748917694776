import styled from "styled-components";

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid
    ${(props) => props.theme.TopicNewsletterCard.borderColor};

  :last-child {
    border-bottom: none;
  }

  @media ${(props) => props.theme.global.breakpoints.tablet} {
    width: calc(50% - 16px);
    border-bottom: none;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 283px;
    border-bottom: none;
  }
`;

type CardImageProps = {
  src: string;
};
export const StyledCardImage = styled.div<CardImageProps>`
  width: 100%;
  height: 164px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;

  @media ${(props) => props.theme.global.breakpoints.tablet} {
    width: 100%;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 283px;
    height: 135px;
  }
`;

export const StyledCardTitle = styled.h4`
  margin: 20px 0 8px 0;
  font-family: ${(props) => props.theme.TopicNewsletterCard.title.fontFamily};
  font-size: ${(props) =>
    props.theme.TopicNewsletterCard.title.fontSize.mobile};
  line-height: ${(props) => props.theme.TopicNewsletterCard.title.lineHeight};
  text-transform: uppercase;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) =>
      props.theme.TopicNewsletterCard.title.fontSize.desktop};
  }

  &.featured {
    margin: 8px 0 8px 0;

    @media ${(props) => props.theme.global.breakpoints.desktop} {
      font-size: 20px;
      padding: 0 0 16px 0;
    }
  }
`;

export const StyledCardIntro = styled.p`
  width: 100%;
  max-width: 450px;
  font-family: ${(props) => props.theme.TopicNewsletterCard.intro.fontFamily};
  font-size: ${(props) => props.theme.TopicNewsletterCard.intro.fontSize};
  line-height: ${(props) => props.theme.TopicNewsletterCard.intro.lineHeight};
  margin: 0 0 20px 0;
`;

export const StyledCardTransmissionTime = styled.span`
  display: block;
  width: 100%;
  font-size: ${(props) =>
    props.theme.TopicNewsletterCard.transmissionTime.fontSize};
  line-height: ${(props) =>
    props.theme.TopicNewsletterCard.transmissionTime.lineHeight};
  text-transform: uppercase;
  margin: 16px 0 16px 0;

  &.featured {
    text-align: center;
    margin: 16px 0 0 0;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: 16px 0 0 0;
  }
`;

export const StyledCardImageWrapper = styled.div`
  position: relative;
`;
