import { DefaultTheme } from "styled-components";

import { Colors, FontFamily, HeaderImages } from "../styles";

export const breakpoints = {
  mobileSmall: `(min-width: 0px) and (max-width: 360px)`,
  mobile: `(min-width: 361px) and (max-width: 745px)`,
  desktop: `(min-width: 745px) and (max-width: 3840px)`,
};

enum FontWeight {
  Normal = 400,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
}

enum BorderRadius {
  Primary = "0px",
  Secondary = "0px",
}
enum BorderWidth {
  Primary = "1px",
}

// create the actual theme here based on the definition in style.d.ts
export const aubi: DefaultTheme = {
  global: {
    breakpoints: breakpoints,
    background: Colors.BackgroundTarmac,
    colors: {
      black: Colors.DarkTarmac,
    },
    fonts: {
      fontFamily: FontFamily.OpenSans,
      fontFamilyCondensed: FontFamily.OpenSans,
      fontFamilyExtra: FontFamily.OpenSans,
    },
    transitions: {
      duration: "100ms",
      speedCurve: "ease-in",
    },
  },

  // basic components:
  components: {
    Buttons: {
      Basic: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: Colors.Tarmac,
        borderRadius: BorderRadius.Primary,
        width: "100%",
        height: "46px",
        backgroundColor: Colors.Tarmac,
        color: Colors.White,
        fontSize: "14px",
        fontFamily: FontFamily.OpenSans,
        fontWeight: FontWeight.Bold,
        letterSpacing: "0.00em",
        desktop: {
          height: "57px",
          fontSize: "18px",
        },
        mobileSmall: {
          height: "40px",
        },
        hover: {
          backgroundColor: Colors.LightTarmac,
          borderColor: Colors.LightTarmac,
          color: Colors.White,
        },
        subscribed: {
          backgroundColor: Colors.LightestTarmac,
          borderColor: Colors.LightestTarmac,
          color: Colors.DarkTarmac,
        },
        checkmark: {
          color: Colors.CeleryGreen,
        },
      },
      Link: {
        display: "flex",
        width: "none",
        marginTop: "14px",
        padding: "0 20px",
        maxWidth: "336px",
        flexDirection: "row-reverse",
        desktop: {
          height: "57px",
          fontSize: "18px",
        },
        icon: {
          margin: "0  0 0 20px",
        },
      },
    },

    SectionHeader: {
      Headline: {
        display: "none",
        displayConfirmation: "flex",
        color: Colors.Black,
        fontFamily: FontFamily.OpenSans,
        fontSize: "18px",
        fontWeight: FontWeight.ExtraBold,
        lineHeight: "24px",

        desktop: {
          fontSize: "22px",
          lineHeight: "24px",
        },
      },
      Border: {
        display: "none",
        borderColor: Colors.Primary,
        marginTop: "12px",
        height: "4px",
        width: "54px",

        desktop: {
          marginTop: "16px",
        },
      },
    },

    Schedule: {
      Text: {
        color: Colors.LightTarmac,
        fontFamily: FontFamily.OpenSans,
        fontSize: "14px",
        fontWeight: FontWeight.Bold,
        lineHeight: "23px",
        marginLeft: "10px",
        desktop: {
          fontSize: "16px",
        },
      },

      Wrapper: {
        marginTop: "4px",
      },
    },

    Text: {
      color: Colors.DarkTarmac,
      fontFamily: FontFamily.OpenSans,
      fontSize: "16px",
      lineHeight: "23px",
      margin: "12px 0",
    },

    InputField: {
      borderRadius: BorderRadius.Primary,
      borderColor: Colors.LightTarmac,
      borderWidth: BorderWidth.Primary,
      backgroundColor: Colors.White,
      color: Colors.DarkTarmac,
      fontFamily: FontFamily.OpenSans,
      fontSize: "18px",

      hover: {
        borderColor: Colors.DarkTarmac,
        color: Colors.DarkTarmac,
      },

      focus: {
        borderColor: Colors.DarkTarmac,
        color: Colors.DarkTarmac,
      },

      error: {
        borderColor: Colors.Primary,
        color: Colors.Primary,
      },

      disabled: {
        borderColor: Colors.LightestTarmac,
        color: Colors.LightestTarmac,
      },
    },
    SubmitButton: {
      backgroundColor: Colors.Tarmac,
      backgroundColorHover: Colors.LightTarmac,
      color: Colors.White,
      borderRadius: BorderRadius.Primary,
      fontFamily: FontFamily.OpenSans,
      fontWeight: FontWeight.Bold,
      fontSize: "14px",

      disabled: {
        backgroundColor: Colors.LighterTarmac,
        color: Colors.DisabledButtonColor,
        fontSize: "14px",
        fontFamily: FontFamily.OpenSans,
      },
    },
    CheckBox: {
      borderWidth: BorderWidth.Primary,
      borderRadius: BorderRadius.Secondary,
      borderColor: Colors.LightTarmac,
    },
    FinePrint: {
      fontFamily: FontFamily.OpenSans,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: FontWeight.Normal,
      lineHeight: "22px",
    },
  },

  // sections:
  mainContent: {
    maxWidth: "910px;",
  },

  header: {
    DescriptionHeadline: {
      lineHeight: "130%",
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "18px",
    },
    DescriptionList: {
      padding: "8px 0px 0px 0px",
      fontSize: "18px",
      gap: "8px",
    },
    Description: {
      gap: "13px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    HeaderBackground: {
      backgroundImage: HeaderImages.AubiMobile,
      backgroundColor: Colors.Primary,
      desktop: {
        backgroundImage: HeaderImages.Aubi,
        backgroundImageConfirmation: HeaderImages.Aubi,
        backgroundImageVerified: HeaderImages.Aubi,
      },
    },
    HeaderHeadline: {
      width: "100%",
      textAlign: "center",
      desktop: {
        padding: "64px 0 0",
      },
    },
    Headline: {
      color: Colors.White,
      fontSize: "28px",
      fontWeight: 800,
      fontFamily: FontFamily.OpenSans,
      lineHeight: "36px",
      width: "100%",
      margin: "5px 0 115px",

      desktop: {
        fontSize: "55px",
        lineHeight: "65px",
        width: "100%",
      },
      mobile: {
        margin: "0 0 30px",
      },
    },
    Subtitle: {
      backgroundColor: Colors.Primary,
      color: Colors.White,
      padding: "0 10px",
      fontSize: "17px",
      lineHeight: "25px",
      fontWeight: FontWeight.SemiBold,
      fontFamily: FontFamily.OpenSans,

      desktop: {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    TextWrapper: {
      borderRadius: BorderRadius.Secondary,
      boxShadow: "none",
      backgroundColor: Colors.White,
      padding: "13px 6px",
      mobile: {
        width: "auto",
        height: "auto",
        padding: "12px",
      },
    },
    TextProps: {
      fontSize: "16px",
      lineHeight: "24px",
      color: Colors.DarkTarmac,
      fontFamily: FontFamily.OpenSans,
    },
  },

  registrationBox: {
    Headline: {
      fontSize: "18px",
      fontWeight: FontWeight.ExtraBold,
      fontFamily: FontFamily.OpenSans,
      desktop: {
        fontSize: "25px",
      },
    },
    Wrapper: {
      backgroundColor: Colors.White,
      padding: "36px 0",
      desktop: {
        padding: "50px",
      },
    },
    Box: {
      backgroundColor: Colors.White,
      borderColor: Colors.LighterTarmac,
      borderRadius: BorderRadius.Primary,
      borderWidth: BorderWidth.Primary,
    },
    OptIn: {
      padding: "25px 0 11px",
      desktop: {
        padding: "30px 0 15px",
      },
    },
  },

  featuredNewsletters: {
    Header: {
      alignSelf: "flex-start",
      margin: "32px 0 0 0",
      desktop: {
        alignSelf: "flex-start",
        margin: "40px 0 0 0",
      },
    },
    Card: {
      CardWrapper: {
        border: "2px solid " + Colors.Grey,
        borderRadius: BorderRadius.Primary,
        height: "413px",
        marginBottom: "25px",

        desktop: {
          height: "242px",
          flexDirection: "row",
        },
      },
      ContentWrapper: {
        padding: "16px",
      },
      ImageWrapper: {
        borderRadius: BorderRadius.Primary,
        height: "198px",
        width: "100%",
        backgroundColor: Colors.Grey,

        desktop: {
          height: "100%",
          width: "430px",
        },
      },
      ImageTitle: {
        display: "none",
        border: "2px solid " + Colors.White,
        padding: "7px",
        color: Colors.White,
        fontFamily: FontFamily.OpenSans,
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "28px",
        margin: "10px",
      },
      Title: {
        color: Colors.Black,
        fontFamily: FontFamily.OpenSans,
        fontWeight: 900,
        fontSize: "36px",
        lineHeight: "36px",
      },
    },
  },

  otherNewsletters: {
    Header: {
      display: "flex",
      desktop: {
        margin: "16px 0 8px",
      },
    },
    Section: {
      width: "100%",
      justifyContent: "space-between",
      desktop: {
        justifyContent: "start",
      },
    },
    Card: {
      CardStyle: {
        backgroundColor: Colors.White,
        borderWidth: BorderWidth.Primary,
        borderColor: Colors.LighterTarmac,
        borderRadius: BorderRadius.Primary,
        width: "46%",
        minHeight: "217px",
        margin: "7.5px 6px",
        padding: "12px 7.5px",

        desktop: {
          height: "438px",
          heightLastCard: "438px",
          margin: "9.34px",
          width: "289px",
          padding: "20px",
        },
        mobileSmall: {
          minHeight: "113px",
          padding: "12px",
        },
      },
      Sponsored: {
        top: "-14px",
        color: Colors.LightTarmac,
        fontSize: "9px",
        fontFamily: FontFamily.OpenSans,
        desktop: {
          top: "-16px",
          fontSize: "11px",
        },
      },
      IconWrapper: {
        imgWidth: "104px",
        imgHeight: "104px",
        desktop: {
          margin: "0 0 7px 0",
          imgWidth: "150px",
          imgHeight: "150px",
        },
        mobileSmall: {
          imgWidth: "89px",
          imgHeight: "89px",
          marginRight: "12px",
        },
      },
      TextWrapper: {
        padding: "0px",
        mobileSmall: {
          alignItems: "flex-start",
          padding: "0px",
        },
        desktop: {
          padding: "0px",
        },
      },
      TitleWrapper: {
        margin: "6px 0 0 0",
        desktop: {
          margin: "0 0 6px 0",
        },
        mobileSmall: {
          minHeight: "22px",
          justifyContent: "space-between",
          width: "100%",
        },
      },
      Title: {
        color: Colors.DarkTarmac,
        fontFamily: FontFamily.OpenSans,
        fontSize: "15px",
        desktop: {
          fontSize: "22px",
          fontWeight: FontWeight.ExtraBold,
        },
        mobileSmall: {
          fontSize: "16px",
          textAlign: "left",
          margin: "0px",
        },
      },
      InfoIconButton: {
        display: "block",
        position: "absolute",
        top: "-20%",
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        mobileSmall: {
          position: "static",
        },
      },
      InfoboxModal: {
        fontSize: "15px",
        padding: "20px 25px",
      },
      Schedule: {
        color: Colors.LightTarmac,
        fontFamily: FontFamily.OpenSans,
        fontSize: "14px",
        fontWeight: FontWeight.Bold,
        margin: "0 0 9px 0 ",
        desktop: {
          fontSize: "16px",
        },
        mobileSmall: {
          fontSize: "13px",
        },
      },
      Text: {
        color: Colors.DarkTarmac,
        fontFamily: FontFamily.OpenSans,
        fontSize: "16px",
        lineHeight: "25px",
        textAlign: "center",
        desktop: {
          padding: "0px 0px",
        },
      },
    },
  },

  selectedNewsletters: {
    Section: {
      desktop: {
        maxWidth: "910px",
      },
    },
    Header: {
      margin: "31px 0 12px",
      desktop: {
        margin: "50px 0 18px 6px",
      },
    },
    Wrapper: {
      padding: "0px",
    },
    Card: {
      border: "1px solid " + Colors.LighterTarmac,
      borderRadius: BorderRadius.Primary,
      desktop: {
        maxWidth: "278px",
        height: "96px",
        alignItems: "center",
      },
    },
    Title: {
      color: Colors.DarkTarmac,
      fontFamily: FontFamily.OpenSans,
      fontSize: "18px",
      fontWeight: FontWeight.ExtraBold,
      lineHeight: "26px",
      desktop: {
        textAlign: "left",
        fontSize: "22px",
        lineHeight: "32px",
      },
    },
  },
} as DefaultTheme;
